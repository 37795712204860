.modal-overlay{
    background-color: rgba(189,189,189,0.5) !important;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: 1000;
    overflow-y: scroll;
}
.modal-content{
    position: absolute;
    border-radius: 1rem;
    width:90rem;
    left:50%;
    transform: translateX(-50%);
    top:5rem;
    padding-bottom: 5rem;
}
.modal-content.md{
    width:50rem;
}
.modal-content.lg{
    width:120rem;
}
.modal-content.maxi{
    width:100%;
    top:0;
    left:0;
    transform: translateX(0);
    bottom:0;
    inset:0;
    padding:0;
}
.modal-content .close{
    position:absolute;
    top:1rem;
    right:1rem;
    cursor:pointer;
}
.modal-content .modal-header{
    padding:2rem;
    display:flex;
    align-items: center;
    box-shadow: 0 0.4rem 0.5rem rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid var(--line-color);
    background: var(--white-color);
    border-radius: 1rem 1rem 0 0;
    position: relative;
}
.modal-content .modal-header h3{
    font-size:2rem;
}
.modal-content .modal-header .btn,
.modal-content .modal-header .input-group{
    margin-left:2rem;
}
.modal-content .modal-header .modal-header-controls{
    margin-left:auto;
    display: flex;
    align-items: center;
}
.modal-content .modal-body{
    padding:2rem 2rem 0 2rem;
    background: var(--white-color);
    position:relative;
}
.modal-content .modal-body .tabs{
    margin:-2rem -2rem 2rem -2rem;
    background-color: var(--ligth-gray-color-3);
    border-bottom: 1px solid var(--line-color);
    box-shadow: 0 0.4rem 0.5rem rgba(0, 0, 0, 0.05);
    display: flex;
}
.modal-content .modal-body .tabs li{
    height:6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    border-left: 1px solid var(--ligth-gray-color-3);
    border-right: 1px solid var(--ligth-gray-color-3);
    flex:1;
    cursor:pointer;
}
.modal-content .modal-body .tabs li.selected:first-child{
    border-left: 1px solid var(--white-color);
}
.modal-content .modal-body .tabs li.selected:last-child{
    border-right: 1px solid var(--white-color);
}
.modal-content .modal-body .tabs li.selected{
    color:var(--blue-color);
    background-color: var(--white-color);
    border-left: 1px solid var(--line-color);
    border-right: 1px solid var(--line-color);
}
.modal-content .modal-body .modal-body-no-side-padding{
    margin:0 -2rem;
}
.modal-content .modal-body .modal-body-no-padding{
    margin:-2rem;
}
.modal-content .modal-footer{
    padding:2rem;
    background: var(--white-color);
    border-radius: 0 0 1rem 1rem;
    width:100%;
}
.modal-content.maxi .modal-header{
    padding:0.5rem;
    border-radius: 0;
}
.modal-content.maxi .modal-header h3{
    font-size:1.4rem;
}
.modal-content.maxi .modal-header .btn,
.modal-content.maxi .modal-header select{
    height:3rem;
    padding:0 1.5rem;
}
.modal-content.maxi iframe{
    width:100%;
    height:calc(100% - 45px);
    border:0px none;
    background-color: #fff;
}