.cm-sidebar{
    position: fixed;
    left:-35rem;
    top:0;
    height:100%;
    width:35rem;
    background-color: var(--white-color);
    box-shadow: 0 0 0.5rem rgba(0,0,0,0.1);
    z-index: 1000;
    transform: translateX(0rem);
    transition: transform 0.5s;
}
.cm-sidebar .cm-sidebar-header{
    padding:2rem;
    border-bottom: 1px solid var(--line-color);
    display: flex;
    align-items: center;
}
.cm-sidebar .cm-sidebar-header .btn{
    height: 3rem;
    padding: 0 1.5rem;
}
.cm-sidebar .cm-sidebar-header img{
    margin-right:1rem;
    height:2.6rem;
    width:2.6rem;
    display: block;
}
.cm-sidebar .cm-sidebar-content{
    padding:2rem;
    overflow-x: hidden;
    overflow-y: auto;
    height:calc(100% - 7.1rem);
}
.cm-sidebar.open{
    transform: translateX(35rem);
}

.cm-sidebar .cm-add-element{
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    height:12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid var(--line-color);
    border-right: 1px solid var(--line-color);
    cursor:pointer;
    box-shadow: 0 0.4rem 0.5rem rgba(0,0,0,0.05);
}
.cm-sidebar .cm-add-element:hover{
    background-color: var(--ligth-gray-color-3);
}
.cm-sidebar .cm-add-element img{
    height:4rem;
    width:4rem;
}
.cm-sidebar .cm-add-element h3{
    font-weight: 400;
    font-size: 1.6rem;
    margin-top:1rem;
    margin-bottom:0;
    text-align: center;
}
.cm-sidebar .cm-add-element:hover h3{
    color:var(--blue-color);
}
.cm-sidebar .cm-remove-section-elm{
    padding-top:2rem;
    border-top:1px dashed var(--line-color);
}
.cm-all-overlay{
    position: fixed;
    inset:0;
    z-index: 999;
}
.cm-responzive-tab{
    font-size:1rem;
    line-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.cm-image-container{
    width:100%;
    position: relative;
    aspect-ratio: 16/9;
    border-radius:1rem;
    border:1px solid var(--line-color);
    overflow: hidden;
    background-color: var(--ligth-gray-color);
}
.cm-image-container-padding{
    padding:2rem;
}
.cm-image-container-selected{
    border:1px solid var(--blue-color);
}
.cm-image-container img,
.cm-image-container video{
    object-fit: contain;
    width: 100%;
    height:100%;
    cursor:pointer;
}
.cm-image-container .select {
    position: absolute;
    top: -0.1rem;
    right: -0.1rem;
    background-color: var(--white-color);
    padding: 0.5rem 0.5rem 0.5rem 0.8rem;
    border-radius: 0 1rem 0 0.6rem;
    border:1px solid var(--line-color);
    z-index: 1;
    display: flex;
}
.cm-image-container .input-group {
    margin-right:1rem;
}
.cm-image-container select {
    padding:0;
    font-size:1.2rem;
}