.list{
    height:calc(100% - 8rem);
}
.list .static{
    width:15rem;
}
.list .static.bigger{
    width:25rem;
}
.list .static.smaller{
    width:10rem;
}
.list .static.smallest{
    width:5rem;
}
.list .static.options{
    width:9.5rem;
}
.list .list-header{
    display: flex;
    align-items: center;
    padding:2rem;
    background-color: var(--ligth-gray-color-3);
    border-bottom:1px solid var(--line-color);
    border-top:1px solid var(--line-color);
    box-shadow: 0 0.4rem 0.5rem 0 rgba(0,0,0,0.05);
}
.list .list-header div{
    font-size:1.2rem;
    text-transform: uppercase;
    font-weight: 600;
    padding:0 1rem;
}
.list .list-header div.img-offset{
    padding:0 0 0 1rem;
    width:6.3rem;
}
.list .list-header.sortable div.img-offset{
    width:10rem;
}
.list .list-header .checkbox{
    padding:0;
}
.list .list-header div:first-child,
.list .list-content .list-row div.list-row-column:first-child{
    padding-left:0;
}
.list .list-content {
    overflow-y: scroll;
    height:calc(100% - 6rem);
}
.list .list-content .list-row{
    display: flex;
    align-items: center;
    padding:1.6rem 2rem;
    border-bottom:1px solid var(--line-color);
    position: relative;
}
.list .list-content .list-row:hover{
    background-color: var(--ligth-gray-color-3);
}
.list .list-content .list-row div.list-row-column{
    font-size:1.4rem;
    line-height: 1.4rem;
    padding:0 1rem;
}
.list .list-content .list-row div.list-row-column .img{
    height:3rem;
    border-radius:0.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.list .list-content .list-row div.list-row-column .img img{
    max-width:100%;
    max-height:100%;
    border-radius: 0.5rem;
}
.list .list-content .list-row img.verified,
.list .list-content .list-row img.cancel{
    height:2.6rem;
    display: block;
    margin:0 auto;
}
.list .list-content .alert{
    margin:2rem;
}
.list .list-content .list-row .dots{
    cursor:pointer;
    padding:0 1rem;
    height:2rem;
}
.list .list-content .list-row .dots img{
    height:2rem;
}
.list .list-content .list-row .list-row-options{
    position:absolute;
    top:calc(100% + 0.5rem) ;
    right:0.5rem;
    padding:0 1.5rem;
    border:1px solid var(--line-color);
    background-color: var(--white-color);
    border-radius: 1rem;
    z-index: 2;
}
.list .list-content .list-row .list-row-options.top{
    top:calc(100% - 20rem);
}
.list .list-content .list-row .list-row-options div.options-row{
    border-bottom:1px solid var(--line-color);
    padding:1.5rem 0;
    font-size: 1.6rem;
    line-height: 1.6rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.list .list-content .list-row .list-row-options div.options-row.no-hover{
    cursor: default;
}
.list .list-content .list-row .list-row-options div.input-group{
    padding:0;
    width:5rem;
}
.list .list-content .list-row .list-row-options div.input-group input{
    padding:0rem 0.5rem;
}
.list .list-content .list-row .list-row-options div.options-row button{
    padding:0 0.5rem;
    margin-left:0.5rem;
    height:3rem;
}
.list .list-content .list-row .list-row-options div.options-row button img{
    margin:0;
}
.list .list-content .list-row .list-row-options div.options-row:not(.no-hover):hover{
    color: var(--blue-color);
}
.list .list-content .list-row .list-row-options div.options-row:last-child{
    border-bottom:0px none;
}
.list .list-content .list-row .list-row-options img{
    margin-right:0.5rem;
    height:2rem;
}
.list .list-content .list-row .list-row-options-overlay{
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 1;
}
.list .list-content .list-row .checkbox{
    padding:0;
}
.list .list-content .list-row .move{
    cursor: move;
    height:2.6rem;
    margin-left:1rem;
}
.list .list-content .list-row .empty-checkbox{
    width:2.8rem;
}
.list .list-content .list-content-section .list-content-section{
    padding-left:1rem;
}
.list .list-content .list-fetch-more{
    display:flex;
    align-items: center;
    padding:1.5rem 0;
    flex-direction: column;
}
.list .list-content .list-fetch-more .pagging{
    display:flex;
    justify-content: center;
    margin-bottom:1.5rem;
}
.list .list-content .list-fetch-more .pagging div{
    cursor:pointer;
    padding:0.5rem 0.8rem;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 0.5rem;
}
.list .list-content .list-fetch-more .pagging div.selected{
    background-color: var(--blue-light-color);
    color:var(--blue-color);
}