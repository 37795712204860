:root{
    --text-color:#777777;
    --white-color:#ffffff;
    --black-color:#222222;
    --ligth-gray-color:#F5F5F5;
    --input-color:#5f5f5f;
    --light-gray-color-2:#DDDDDD;
    --ligth-gray-color-3:#F9F9F9;
    --line-color:#ECECEC;
    --yellow-color:#FFDF5D;
    --yellow-text-color:#F1C530;
    --over-yellow-text:#9B7F0A;
    --yellow-light-color:#FDF6E0;
    --blue-color:#43AAE3;
    --blue-light-color:#D9EEF9;
    --brown-color:#D28E5D;
    --brown-light-color:#F8EEE7;
    --green-light-color:#E7F8C4;
    --green-text-color:#86B02E;
}
::-webkit-scrollbar {
    width: 5px!important;
    height: 5px!important;
}
::-webkit-scrollbar-track {
    background: var(--ligth-gray-color)!important;
}
::-webkit-scrollbar-thumb {
    background: #b1b1b1!important;
}
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html,body {
	font-size:10px;
	color:var(--text-color);
	background-color:var(--ligth-gray-color);
	font-family:'Barlow', sans-serif;
	font-weight:400;
    max-width: 100vw;
    overflow: hidden;
    height:100%;
}
body.ReactModal__Body--open{
	overflow:hidden;
}
:focus {
    outline: none;
}
a,
a:hover{
	color:var(--blue-color);
	cursor:pointer;
    font-size:1.6rem;
}
p,li{
  font-size:1.6rem;
  line-height: 2rem;
}
span{
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}
hr{
    background-color: var(--line-color);
    height:1px;
    border: 0px none;
    margin: 0 0 1.5rem 0;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
    font-size: 16px;
}
input, select {
    background-color: var(--white-color);
    border: 1px solid var(--line-color);
    border-radius: 1rem;
    font-size: 1.6rem;
    line-height: 1.6rem;
    width: 100%;
    padding: 0.9rem 1rem 1rem 1rem;
    font-family: inherit;
    display: block;
    font-weight: 300;
    color: var(--input-color);
}
input[type="file"] {
    background-color: var(--white-color);
    border: 1px solid var(--line-color);
    border-radius: 1rem;
    font-size: 1.6rem;
    line-height: 1.6rem;
    width: 100%;
    padding: 0.8rem 0 0.8rem 0;
    font-family: inherit;
    display: block;
}
input[type="file"].narrow {
    padding: 0.4rem 0 0.4rem 0;
}
ul{
    list-style-type: none;
}
h1{
    font-size: 2rem;
    line-height: 3rem;
    margin:0;
}
label{
    font-size:1.4rem;
    line-height: 1.4rem;
    font-weight: 600;
    margin-bottom:0.5rem;
    display: inline-block;
    padding-left:0.8rem;
}
#root{
    height:100%;
}
.whole-content{
    padding-top:6rem;
    padding-left:26.5rem;
    height:100%;
    transition: padding 0.5s;
}
.whole-content.shrink{
    padding-left:6.6rem;
}
.l-content{
    background-color: var(--white-color);
    box-shadow: 0 0 1.5rem 0 rgba(0,0,0,0.05);
    border-radius: 1rem 0 0 0;
    height:100%;
}
.content-header{
    padding:2rem;
    display:flex;
    align-items: center;
}
.content-header .buttons{
    display:flex;
    align-items: center;
    margin-left: auto;
}
.content-header .buttons .btn{
    margin-left:1.5rem;
}
.content-body{
    padding:2rem;
    border-top:1px solid var(--line-color);
    height:calc(100% - 8rem);
    overflow-y: scroll;
}
.input-group {
    border: 1px solid var(--line-color);
    border-radius: 1rem;
    position: relative;
}
.input-group .preppend {
    align-items: center;
    border-radius: 1rem 0 0 1rem;
    justify-content: center;
    width: 4.6rem;
    display: flex;
}
.input-group .append {
    align-items: center;
    border-radius: 0 1rem 1rem 0;
    justify-content: center;
    width: 4.6rem;
    display: flex;
}
.input-group .preppend img,
.input-group .append img{
    max-height:3rem;
}
.input-group, 
.input-group .preppend {
    background-color: var(--white-color);
    display: flex;
}
.input-group input,
.input-group select {
    border: 0;
}
.input-group input.with-preppend{
    padding-left:0;
}
.input-group input.with-append{
    padding-right:0;
}
.input-group .file-input-overlay{
    position:absolute;
    pointer-events: none;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding:0 0 0 1rem;
    background-color: var(--white-color);
    display: flex;
    align-items: center;
    border-radius: 1rem;
    cursor: pointer;
    font-size:1.4rem;
    display: flex;
    align-items: center;
}
.input-group .file-input-overlay .btn{
    margin-left:auto;
}
.input-group.select-box{
    height:4rem;
}
.input-group.select-box ul{
    position: absolute;
    top:4rem;
    left:0;
    max-height:25rem;
    overflow-x: hidden;
    overflow-y: auto;
    padding:0.5rem 1rem;
    z-index: 10;
    border-left:1px solid var(--line-color);
    border-right:1px solid var(--line-color);
    border-bottom:1px solid var(--line-color);
    width:100%;
    background-color: var(--white-color);
    box-shadow: 0 0.4rem 0.5rem rgba(0,0,0,0.05);
    border-radius:0 0 1rem 1rem;
}
.input-group.select-box ul li{
    width:100%;
    cursor:pointer;
    padding:0.1rem 0;
    
}
.input-group.select-box ul li img{
    height:3rem;
    margin-right:1rem;
}
.input-group.select-box ul li span{
    display: flex;
    padding:0.5rem;
    width:100%;
    align-items: center;
}

.input-group.select-box ul li span:hover{
    color: var(--blue-color);
}
.input-group.select-box ul li ul{
    position:relative;
    padding:0 0 0 1rem;
    top:0;
    left:0;
    border:0px none;
    box-shadow: none;
    max-height:none;
}
.input-group.select-box ul li:last-child{
    border-bottom:0px none;
}
.input-group.select-box .value{
    border-radius: 1rem;
    padding:0 3rem 0 1rem;
    width:100%;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    color: var(--input-color);
    font-weight: 300;
}
.input-group.select-box .value img:not(.item-img){
    position: absolute;
    top:50%;
    right:1rem;
    transform: translateY(-50%) rotateZ(135deg);
}
.input-group.select-box .value img.item-img{
    height:2.8rem;
    margin-right:1rem; 
}
.input-group.select-box .overlay{
    z-index: 1;
    position: fixed;
    inset: 0;
}
.input-group.only-button{
    width:15rem;
    border: 0px none;
}
.input-group.only-button .file-input-overlay{
    padding:0;
}
.input-group .color-picker{
    position:absolute;
    top:4rem;
    bottom:auto;
    left:0;
    z-index: 2;
}
.input-group .color-picker.top{
    top:auto;
    bottom:4rem;
}
.input-group .color-picker-overlay{
    position:fixed;
    inset:0;
    z-index: 1;
}
.input-group textarea{
    border:0px none;
    width: 100%;
    height:20rem;
    background-color: var(--white-color);
    border-radius: 1rem;
    font-size: 1.6rem;
    line-height: 1.6rem;
    width: 100%;
    padding: 1rem;
    font-family: inherit;
    display: block;
    font-weight: 300;
    color: var(--input-color);
}
.open-image-container img{
    width:100%;
    margin-top:1rem;
    border-radius:1rem;
}
.checkbox,
.radio{
  display:flex;
  align-items: center;
  position:relative;
  cursor: pointer;
}
.checkbox input,
.radio input{
  cursor: pointer;
  height:1.8rem;
  width: 1.8rem;
}
.checkbox:before,
.radio:before{
  content:" ";
  width:2rem;
  height:2rem;
  border:1px solid var(--line-color);
  position:absolute;
  top:50%;
  transform: translateY(-50%);
  left:-0.3rem;
  z-index: 1;
  background-color: var(--white-color);
  border-radius: 0.5rem;
  pointer-events: none;
}
.radio:before{
  border-radius: 10rem;
}
.checkbox.checked:after{
  content: "\2713";
  width: 2rem;
  height: 2rem;
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  left: -0.2rem;
  z-index: 2;
  font-size: 1.3rem;
  font-weight: 600;
  background-color: var(--blue-color);
  color: #fff;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.radio.checked:after {
    content: " ";
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.3rem;
    z-index: 2;
    background-color: var(--blue-color);
    border-radius: 1rem;
    pointer-events: none;
}
.checkbox label,
.radio label{
  font-size: 1.4rem;
  line-height: 1.4rem;
  cursor: pointer;
  padding-left:1rem;
  padding-right:1.5rem;
  font-weight:400;
  margin:0;
}
.checkbox label.color span{
    width: 50px;
    height: 18px;
    display: block;
    border-radius: 1rem;
}
.radio-container{
    display: flex;
    padding:0.5rem;
}
.card{
    border-radius:1rem;
    background-color: var(--white-color);
    box-shadow: 0 0 1.5rem rgba(0,0,0,0.07);
}
.card .card-body{
    padding:3rem;  
}
.content-manager{
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 1000;
    overflow: hidden;
}
.input-card{
    border-radius:1rem;
    background-color: var(--white-color);
    box-shadow: 0 0.4rem 0.5rem rgba(0,0,0,0.05);
    border:1px solid var(--line-color);
}
.input-card.tm{
    margin-top:1.8rem;
}
.input-card .input-card-header{
    padding:0 1.5rem;  
    height:4rem;
    display:flex;
    align-items: center;
}
.input-card .input-card-header h3{
    font-size:1.6rem;
    line-height: 1.6rem;
    display: flex;
    align-items: center;
}
.input-card .input-card-header img{
    margin-right:1rem;
    max-height:2rem;
}
.input-card .input-card-header img.minimalized{
    margin-right: 0rem;
    width: 1.4rem;
    height: 1.4rem;
    transform: rotateZ(135deg);
    position: relative;
    top: -0.3rem;
    transition: all 0.5s;
    margin-left:1rem;
}
.input-card .input-card-header img.minimalized.closed{
    transform: rotateZ(45deg);
    top: 0rem;
}
.input-card .input-card-header .info-container{
    position:relative;
    margin-left:0.5rem;
    display: flex;
}
.input-card .input-card-header .info-container:hover .info-content{
    display:block;
}
.input-card .input-card-header .info-container .info-icon{
    cursor:pointer;
    margin:0;
    height:1.6rem;
}
.input-card .input-card-header .info-container .info-content{
    position:absolute;
    top:0;
    left:4rem;
    background-color: var(--black-color);
    width:25rem;
    padding: 0 1rem;
    border-radius: 1rem;
    display:none;
    z-index: 5;
}
.input-card .input-card-header .info-container .info-content p{
    margin:1rem 0;
    font-size:1.4rem;
    line-height: 1.6rem;
    color:var(--light-gray-color-2);
}
.input-card .input-card-header .btn{
    margin-left:1rem;
}
.input-card .input-card-body{
    position:relative;
    border-top:1px solid var(--line-color);
}
.input-card .input-card-body .input-card-body-content{
    padding:2rem 2rem 0.01rem 2rem;  
}
.input-card .input-card-body .list,
.input-card .input-card-body .list .list-content{
    height:auto;
}
.input-card .input-card-body .list .list-content{
    overflow: initial;
}
.input-card .input-card-body.can-be-minimalized{
    transition: height 0.5s, padding 0.5s; 
}
.input-card .input-card-body.closed{
    height:0px !important;
    padding:0;
    border-top:0px none;
}
.input-card .tabs{
    list-style-type: none;
    display: flex;
    background-color: var(--ligth-gray-color-3);
    box-shadow: 0 0.4rem 0.5rem rgba(0,0,0,0.05);
    border-bottom: 1px solid var(--line-color); 
    border-top: 1px solid var(--line-color); 
}
.input-card .tabs li{
    border-right:1px solid var(--line-color);
    flex:1;
    text-align: center;
    padding:1rem 0rem;
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input-card .tabs li img{
    max-height:2rem;
    display: block;
}
.input-card .tabs li.selected{
    background-color: var(--white-color);
    color: var(--blue-color);
}
.input-card .tabs li:last-child{
    border-right:0px none;
}
.input-card .list .list-content .list-row:last-child{
    border-bottom:0px none;
}
.input-card .list .list-content .data-list-item .list-row{
    border-bottom:1px solid var(--line-color);
}
.input-card .list .list-content .data-list-item:last-child .list-row{
    border-bottom:0px none;
}
.data-list-item{
    left: auto !important; top: auto !important;
}
.responzive-info{
    top:0.5rem;
    left:0.5rem;
    margin:-1.5rem 0 1.5rem -1rem;
    font-size: 1rem;
    color: var(--blue-color);
    border-bottom:1px dashed var(--line-color);
    padding-bottom:0.5rem;
}
.product-dimension-type{
    width:21rem;
    margin:6rem auto;
    position:relative;
}
.product-dimension-type img{
    width:100%;
}
.product-dimension-type .input-group{
    position:absolute;
    width:4rem;
}
.product-dimension-type .input-group input{
    padding:0.2rem 0.5rem;
    text-align:center;
}
.notifications{
    position:fixed;
    bottom:0;
    left:0;
    width:30rem;
    padding:2rem;
    z-index: 2000;
}
.notifications .alert{
    margin:1.5rem 0 0 0;
    text-align: left;
}
.badge{
    padding:0.25rem 1rem;
    font-size:1.2rem;
    color:var(--black-color);
    background-color: var(--yellow-light-color);
    border:1px solid var(--yellow-text-color);
    border-radius: 1rem;
}
.loading{
    padding: 5rem 0;
    text-align: center;
}
.alert{
    padding:1.25rem;
    text-align:center;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 1rem;
    margin-bottom:2rem;
    background-color: var(--white-color);
    display: flex;
    align-items: center;
}
.alert img{
    margin-right:1rem;
    height:2.6rem;
}
.alert-danger{
    background-color: var(--brown-light-color);
    color:var(--brown-color);
    border:1px solid var(--brown-color);
}
.alert-warning{
    background-color: var(--yellow-light-color);
    color:var(--yellow-text-color);
    border:1px solid var(--yellow-text-color);
}
.alert-success{
    background-color: var(--green-light-color);
    color:var(--green-text-color);
    border:1px solid var(--green-text-color);
}
.row{
    display:flex; 
    flex-wrap: wrap;
    margin:0 -1rem;
}
.col-100{
    flex:0 0 100%;
    max-width:100%;
    padding: 0 1rem;
}
.col-75{
    flex:0 0 75%;
    max-width:75%;
    padding: 0 1rem;
}
.col-66{
    flex:0 0 66.6666%;
    max-width:66.6666%;
    padding: 0 1rem;
}
.col-60{
    flex:0 0 60%;
    max-width:60%;
    padding: 0 1rem;
}
.col-50{
    flex:0 0 50%;
    max-width:50%;
    padding: 0 1rem;
}
.col-40{
    flex:0 0 40%;
    max-width:40%;
    padding: 0 1rem;
}
.col-33{
    flex:0 0 33.3333%;
    max-width:33.3333%;
    padding: 0 1rem;
}
.col-25{
    flex:0 0 25%;
    max-width:25%;
    padding: 0 1rem;
}
.col-20{
    flex:0 0 20%;
    max-width:20%;
    padding: 0 1rem;
}
.col-16{
    flex:0 0 16.6666%;
    max-width:16.6666%;
    padding: 0 1rem;
}
.col-40{
    flex:0 0 40%;
    max-width:40%;
    padding: 0 1rem;
}
.form-group{
    margin-bottom:2rem;
}
.form-text{
    font-size: 1.6rem;
    line-height: 1.6rem;
    width: 100%;
    padding: 1.2rem 1rem;
    font-family: inherit;
    font-weight: 300;
    color: var(--input-color);
}
.btn{
    height:4rem;
    display: flex;
    align-items: center;
    padding:0 2rem;
    border-radius: 1rem;
    box-shadow: 0 0.4rem 0.4rem rgba(0,0,0,0.05);
    border:0px none;
    justify-content: center;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 700;
    font-family: inherit;
    cursor:pointer;
}
.btn.narrow{
    height:3rem;
}
.btn svg{
    height:3.2rem;
    width:3.2rem;
}
.btn svg rect{
    fill:var(--over-yellow-text);
}
.btn-primary,
.btn-primary:hover{
    background-color: var(--yellow-color);
    color:var(--over-yellow-text);
}
.btn-brown,
.btn-brown:hover{
    background-color: var(--brown-color);
    color:var(--white-color);
}
.btn-blue,
.btn-blue:hover{
    background-color: var(--blue-color);
    color:var(--white-color);
}
.text-center{
    text-align:center;
}
.text-right{
    text-align:right;
}
.overflow-hidden{
    overflow:hidden;
}
.full-width{
    width:100%;
}
.flex-1{
    flex:1;
}
.ml-auto{
    margin-left:auto !important;
}
.w-100{
    width:100%;
}
.fs-24{
    font-size:2.4rem;
}
.fs-22{
    font-size:2.2rem;
}
.fs-20{
    font-size:2rem;
}
.fs-18{
    font-size:1.8rem;
}
.fs-16{
    font-size:1.6rem;
}
.fs-14{
    font-size:1.4rem;
}
.fs-12{
    font-size:1.2rem;
}
.d-flex{
    display: flex;
}
.no-margin{
    margin:0 !important;
}
.no-padding{
    padding:0 !important;
}
.no-padding-right{
    padding-right:0 !important;
}
.only-bottom-padding{
    padding-left:0rem !important;
    padding-right:0rem !important;
    padding-top:0rem !important;
}
.no-top-border{
    border-top:0px none !important;
}
.justify-content-center{
    justify-content: center;
}
.align-items-center{
    align-items: center;
}
.cursor-pointer{
    cursor:pointer;
}
.cursor-default{
    cursor:default !important;
}
.aspect-ratio-16-9{
    aspect-ratio: 16/9;
}
.aspect-ratio-1-1{
    aspect-ratio: 1/1;
}
.aspect-ratio-4-3{
    aspect-ratio: 4/3;
}
.mirrored-verticaly{
    transform: scaleX(-1);
}
.tox-tinymce{
    border:1px solid var(--line-color) !important;
    box-shadow: 0 0.4rem 0.5rem rgba(0,0,0,0.05) !important;
}
.sketch-picker{
    border-radius: 1rem !important;
}
.sketch-picker label{
    padding-left:0  !important;
    margin-bottom: 0 !important;
}
.sketch-picker #rc-editable-input-2,
.sketch-picker #rc-editable-input-4,
.sketch-picker #rc-editable-input-6,
.sketch-picker #rc-editable-input-8,
.sketch-picker #rc-editable-input-10{
    text-align: center !important;
    width: 100% !important;
}
