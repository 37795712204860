.auth-panel{
    display:flex;
    align-items: center;
    flex-direction: column;
    background-image: url("../Media/Images/login_bg.webp");
    background-position: center center;
    padding:10rem 0 22rem 0;
    background-repeat: no-repeat;
}
.auth-panel .logo{
    margin-bottom:5rem;
}
.auth-panel .card{
    width:50rem;
}